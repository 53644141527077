<template>
  <div class="w-full pt-5">
    <div class="flex w-full">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Performance Agreements
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>

    <div class="w-full p-5 flex mt-1 flex-col">
      <Tabs
        class="mt-6 mx-3"
        :tabs="tabsItems"
        :active-tab="activeTab"
        @currentTab="activeTab = $event"
        :border="true"
        v-if="tabsItems.length > 0"
      />
      <div class="w-full flex flex-col">
        <SharedAgreements v-if="activeTab === 'Direct Report Agreements'" />
          <TDirectReports v-if="activeTab === 'Direct Report Appraisals'" />
          <AppraisalScorecard v-if="activeTab === 'Appraisal Scorecard'" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Loader from "@/components/Loader";
import SharedAgreements from "./SharedAgreements";
import TDirectReports from "../../Performance/Appraisals/Tabs/TDirectReports";
import AppraisalScorecard from '../../Performance/Appraisals/Tabs/TAppraisalScorecard';

export default {
  name: "MSSPerformance",
  components: {
    Breadcrumb,
    Tabs,
    Loader,
    SharedAgreements,
    TDirectReports,
    AppraisalScorecard
  },
  data() {
    return {
      loadingData: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreements",
          href: "EssMSSPerformance",
          id: "Agreements",
        },
      ],
      tabsItems: ['Direct Report Agreements', 'Direct Report Appraisals', 'Appraisal Scorecard'],
      activeTab: 'Direct Report Agreements',
    };
  },
};
</script>
